import React, { Component } from 'react'
import { graphql, Link } from 'gatsby'

import SEO from '../components/seo'
import getPageLinks from '../utils/getPageLinks'
import aboutArrow from '../assets/images/about-arrow.svg'

import doggo from '../assets/images/doggo.jpg'
import pottery from '../assets/images/pottery.jpg'
import cage from '../assets/images/cage.jpg'
import PageSwitch from '../components/pageSwitch'
import { TransitionPortal } from 'gatsby-plugin-transition-link'

class AboutContent extends Component {
  render() {
    let doggoMove = ev => this.refs[`dog`].style.transform = `translate(${ev.clientX + 20}px, ${ev.clientY - 65}px)`
    let potteryMove = ev => this.refs[`pot`].style.transform = `translate(${ev.clientX + 20}px, ${ev.clientY - 65}px)`
    let cageMove = ev => this.refs[`cage`].style.transform = `translate(${ev.clientX + 20}px, ${ev.clientY - 65}px)`
    return (
      <>
        <section className="about__header">
          <Link to="/">
            <img src={aboutArrow} alt="Cam Richards - Back Home" />
          </Link>
          <a className="git" href="mailto:richards.cameron68@gmail.com">Get In Touch</a>
        </section>
        <section className="about__body">
          <div className="about__content">
            <p>Hi there,</p>
            <p className="grey">I’m Cam a freelance UX & Product designer based in Melbourne. I believe good design is equal parts deep understand, distinctive thinking and detailed delivery. When I’m not designing I can be found doing <span onMouseMove={potteryMove}>pottery<img ref={`pot`} src={pottery} alt='Pottery' /></span>, hanging with my <span onMouseMove={doggoMove}>dog<img ref={`dog`} src={doggo} alt='Doggo' /></span> or learning from the master, <span onMouseMove={cageMove}>Nicholas Cage<img ref={`cage`} src={cage} alt='Nicholas Cage' /></span>.</p>
            <p>Currently open to opportunities. If you want to talk or get a coffee feel free to get in touch. 🤙</p>
          </div>
          <div className="about__sidebar">
            <ul>
              <li><strong>Clients</strong></li>
              <li><Link to="/">Australia Post</Link></li>
              <li><Link to="/">Origin Energy</Link></li>
              <li><Link to="/">Caydon Property Group</Link></li>
              <li><Link to="/">City of Ballarat</Link></li>
              <li><Link to="/">Epworth Healthcare</Link></li>
              <li><Link to="/">Etihad Stadium</Link></li>
              <li><Link to="/">Freeze MND</Link></li>
              <li><Link to="/">That Sugar Movement</Link></li>
            </ul>
          </div>
        </section>
      </>
    )
  }
}  

class AboutPage extends Component {
  render() {
    const { location } = this.props
    return (
      <div>
        <SEO
          title="Cam Richards"
          description="User Experience & Product Designer"
          bodyClass="about"
        />
        <div className="main-wrap main-wrap-about">
          <div className="page-main-about main">
            <div><AboutContent /></div>
          </div>
          <PageSwitch location={location} />
        </div>
      </div>
    )
  }
}

export default AboutPage
